<script>

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';



/**
 * Dashboard Component
 */
export default {
    page: {
    title: "Secteurs",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Secteurs",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Secteurs",
          active: true,
        },
      ],
      forests: [],
      loading: false,
      status: 201,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('ctaf/forests?only=ctaf'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.forests = data.data
      })
    },
  },
  mounted() {
    this.loadList()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des secteurs</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>secteurs</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Forêt</th>
                    <th>Email</th>
                    <th>Surface</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(forest, i) in forests" :key="forest.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <router-link :to="'/ctaf/forests/'+forest.id">{{ forest.name }}</router-link>
                    </td>
                    <td>{{ forest.email }}</td>
                    <td>{{ forest.surface }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
